const getNewsOtherLangSlug = (slug, firstOtherLangSite) => {
	if (firstOtherLangSite === 'en') {
		return `en/news/${slug}`;
	} if (firstOtherLangSite === 'de') {
		return `de/nachricht/${slug}`;
	}
	return `aktualnosci/${slug}`;
};

export default getNewsOtherLangSlug;
